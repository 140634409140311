$main-color: rgb(133, 197, 85)
$hover-color: rgb(126, 182, 83)

.about
    background-color: rgb(218, 245, 197)
    padding: 40px 0
    &_content
        padding: 40px 0
        padding: 0 70px
        .title
            display: flex
            justify-content: flex-start
            align-items: center
            margin-bottom: 20px
            color: #000000
            h2
                
                font-family: "Montserrat", Sans-serif
                font-size: 42px
                font-weight: 600
    &_text
        margin-bottom: 20px
        color: #000000
        font-family: "Montserrat", Sans-serif
        font-size: 18px
        font-weight: 400
        text-align: justify
        text-indent: 55px
        &:last-child
            margin-bottom: 0
@media ( max-width:  767px)
    .about
        padding: 30px 0
        &_content
            padding: 30px 0
            padding: 0 40px
@media ( max-width:  575px)
    .about
        padding: 20px 0
        &_content
            padding: 20px 0
            padding: 0 20px
        &_text
            margin-bottom: 10px
            font-size: 13px
    