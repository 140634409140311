.overlay-loader {
	display: block;
	margin: auto;
	width: 150px;
	height: 150px;
	position: relative;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}
.loader {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	width: 150px;
	height: 150px;
	animation-name: rotateAnim;
		-o-animation-name: rotateAnim;
		-ms-animation-name: rotateAnim;
		-webkit-animation-name: rotateAnim;
		-moz-animation-name: rotateAnim;
	animation-duration: 0.4s;
		-o-animation-duration: 0.4s;
		-ms-animation-duration: 0.4s;
		-webkit-animation-duration: 0.4s;
		-moz-animation-duration: 0.4s;
	animation-iteration-count: infinite;
		-o-animation-iteration-count: infinite;
		-ms-animation-iteration-count: infinite;
		-webkit-animation-iteration-count: infinite;
		-moz-animation-iteration-count: infinite;
	animation-timing-function: linear;
		-o-animation-timing-function: linear;
		-ms-animation-timing-function: linear;
		-webkit-animation-timing-function: linear;
		-moz-animation-timing-function: linear;
}
.loader div {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	border: 2px solid rgb(0,0,0);
	position: absolute;
	top: 3px;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
}
.loader div:nth-child(odd) {
	border-top: none;
	border-left: none;
}
.loader div:nth-child(even) {
	border-bottom: none;
	border-right: none;
}
.loader div:nth-child(2) {
	border-width: 3px;
	left: 0px;
	top: -6px;
	width: 18px;
	height: 18px;
}
.loader div:nth-child(3) {
	border-width: 3px;
	left: -2px;
	top: 5px;
	width: 27px;
	height: 27px;
}
.loader div:nth-child(4) {
	border-width: 5px;
	left: -2px;
	top: -6px;
	width: 36px;
	height: 36px;
}
.loader div:nth-child(5) {
	border-width: 5px;
	left: -2px;
	top: 6px;
	width: 48px;
	height: 48px;
}
.loader div:nth-child(6) {
	border-width: 6px;
	left: 0px;
	top: -6px;
	width: 60px;
	height: 60px;
}
.loader div:nth-child(7) {
	border-width: 6px;
	left: 0px;
	top: 9px;
	width: 75px;
	height: 75px;
}


@keyframes rotateAnim {
	from {
		transform: rotate(360deg);
	}
	to {
		transform: rotate(0deg);
	}
}

@-o-keyframes rotateAnim {
	from {
		-o-transform: rotate(360deg);
	}
	to {
		-o-transform: rotate(0deg);
	}
}

@-ms-keyframes rotateAnim {
	from {
		-ms-transform: rotate(360deg);
	}
	to {
		-ms-transform: rotate(0deg);
	}
}

@-webkit-keyframes rotateAnim {
	from {
		-webkit-transform: rotate(360deg);
	}
	to {
		-webkit-transform: rotate(0deg);
	}
}

@-moz-keyframes rotateAnim {
	from {
		-moz-transform: rotate(360deg);
	}
	to {
		-moz-transform: rotate(0deg);
	}
}