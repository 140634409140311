@import-normalize;

* {
	box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body{
    position: relative;
    background-color: #fff;
    font-family: "Montserrat", Sans-serif;
}

p{
	margin-bottom: 0;
}
.container{
    max-width: 1430px;
}

.navlink {
    color:#000;
}
a:hover {
    text-decoration: none; 
}

