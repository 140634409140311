.product
	background-color: rgb(218, 245, 197)
	overflow: hidden
.product_block
	padding-bottom: 40px
	min-height: 300px

.products_img 
	width: 100%
	height: 500px
	object-fit: contain
	margin-top: 15px
	cursor: pointer
	&_smile 
		max-width: 100%
		height: auto
		margin-top: 15px
		border: 1px solid black
		cursor: pointer
	

.product_name 
	display: flex
	align-items: center
	justify-content: flex-start
	padding: 20px 0
	font-size: 40px
	font-weight: 600
	line-height: 1

.product-description 
	margin: 15px 50px 40px auto

.availability 
	margin: 16px 0
	&_yes 
		color: #ffffff
		background-color: #28a745
		font-weight: 600
		border-radius: 3px
		width: 15%
		text-align: center
		padding: 2px 8px
	&_no
		color: #ffffff
		background-color: red
		font-weight: 600
		border-radius: 3px
		width: 15%
		text-align: center
		padding: 2px 8px

p 
	font-weight: 500
	white-space: pre-wrap
@media ( max-width:  991px)
	.products_img 
		height: 400px
		
	.product_p 
		font-weight: 500
		margin-bottom: 10px
		white-space: pre-wrap
		font-size: 15px
		line-height: 1.4
@media ( max-width:  767px)
	.product_name
		font-size: 30px
