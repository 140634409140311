$main-color: rgb(133, 197, 85)
$hover-color: rgb(126, 182, 83)
.header
    background: url('../../img/bg/header.jpg') center (center / cover) no-repeat
    width: 100%
    &_content
        display: flex
        min-height: 120px
    &_search
        display: flex
        justify-content: center
        align-items: center
        width: calc(100% - 130px)
        padding-top: 10px
        height: 100px
        padding-left: 15px
        &_container
            position: relative
            width: 300px
        &_input
            width: calc(100% - 41px)
            height: 41px
            font-size: 15px
            border: 2px solid rgba(129,129,129,.2)
            border-radius: 8px 0 0 8px
            padding-left: 15px
            
            &:focus
                outline: none
                border: 2px solid black
            &_btn
                position: absolute
                display: flex
                justify-content: center
                align-items: center
                width: 41px
                height: 41px
                background-color: $main-color
                border: none
                right: 0
                top: 0
                font-weight: 300
                border-radius: 0 8px 8px 0
                &:hover
                    background-color: $hover-color
                i
                    color: #fff
                    font-size: 20px
    &_phone
        width: calc(100% - 130px)
        height: 100px
        display: flex
        justify-content: center
        align-items: center
        font-size: 18px
        font-weight: 600
        color: #fff
        line-height: 1.3
        padding-top: 10px
        &_content
            text-align: right
            max-width: 400px
            padding-right: 15px
            span
                display: inline-block
                a
                    // margin-left: 20px
                    text-decoration: none
                    color: #fff
    &_logo
        width: 260px
        height: 100px
        display: flex
        align-items: center
        position: relative
        margin-bottom: 10px
        &_img
            width: 100%
            height: 100%
            display: flex
            justify-content: center
            img
                display: block
                width: auto
                height: auto
        &_text
            position: absolute
            bottom: -15px
            width: 300px
            color: purple
            left: 40%
            font-family: 'Marck Script', cursive
            font-size: 20px
@media ( max-width: 992px )
    .header
        &_content
        &_search
            &_container
                width: 250px
        &_phone
            font-size: 18px
@media ( max-width: 900px )
    .header
        &_content
        &_search
            &_container
                width: 200px
        &_phone
            font-size: 16px
@media ( max-width: 768px )
    .header
        background: url('../../img/bg/header_768.jpg') center (center / cover) no-repeat
        &_content
            flex-direction: column-reverse
            align-items: center
        &_search
            height: 100%
            margin-bottom: 15px
            &_container
                width: 300px
        &_phone
            max-height: 100px
            font-size: 18px
            margin-top: 5px
            line-height: 1.2
            &_content
                text-align: center
        &_logo
            &_text
                left: 30%
@media ( max-width: 550px )
    .header
        &_logo
            &_text
                font-size: 16px
                width: 250px
                left: 10%
@media ( max-width: 450px )
    .header
        &_content
        &_search
            &_container
                width: 100%
        &_phone
            font-size: 16px
            margin-top: 0
        
@media ( max-width: 400px )
    .header
        &_content
        &_search
            width: 100%
            padding: 0 40px
            &_container
                width: 100%
        &_phone
            width: 100%
            font-size: 16px
            padding: 0 15px
        &_logo
            &_text
                bottom: -5px
// @media ( max-width: 991px )
//     .header
//         &_search
//             width: 100%
//             &_input
//                 height: 41px
//                 font-size: 15px
//                 &_btn
//                     width: 41px
//                     height: 41px
// @media ( max-width: 797px )
//     .header
//         height: 115px
//         &_phone
//             height: 100px
//             font-size: 14px
//             line-height: 1.2
//         &_logo
//             height: 100px
//         &_search
//             &_input
//                 height: 35px
//                 font-size: 15px
//                 padding-left: 5px
//                 &_btn
//                     width: 35px
//                     height: 35px
// @media ( max-width: 676px )
//     .header
//         height: 95px
//         &_phone
//             height: 100px
//             font-size: 11px
//             line-height: 1.2
//         &_logo
//             height: 100px
//         &_search
//             &_input
//                 height: 30px
//                 padding-left: 5px
//                 &_btn
//                     width: 30px
//                     height: 30px
    
// @media ( max-width: 576px )
//     .header
//         height: 50px
//         &_search
//             height: 50px
//             &_input
//                 height: 30px
//                 font-size: 8px
//                 padding-left: 2px
//                 &_btn
//                     width: 30px
//                     height: 30px
//                     font-weight: 300
//                     i
//                         font-size: 15px
//         &_phone
//             height: 50px
//             font-size: 9px
//             font-weight: 400
//             line-height: 1
//             padding: 0 5px
//         &_logo
//             height: 50px
//             padding: 0 15px
// @media ( max-width: 417px )
//     .header
//         &_search
//             height: 60px
//             padding-right: 0px
//             &_input
//                 height: 30px
//                 font-size: 7px
//                 padding-left: 0px
//                 &_btn
//                     width: 23px
//                     height: 30px
//                     font-weight: 300
//                     i
//                         font-size: 15px
//         &_phone
//             height: 60px
//             font-size: 7px
//             font-weight: 300
//             line-height: 1
//         &_logo
//             height: 60px