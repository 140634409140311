$main-color: rgb(133, 197, 85)
$hover-color: rgb(126, 182, 83)
.contacts
    background-color: rgb(218, 245, 197)
    padding: 40px 0
    &_content
        padding: 40px 0
        padding: 0 70px
        .title
            display: flex
            justify-content: flex-start
            align-items: center
            margin-bottom: 20px
            color: #000000
            h2
                
                font-family: "Montserrat", Sans-serif
                font-size: 42px
                font-weight: 600
    &_element
        .company
            &_title
                h3
                    color: #000000
                    font-family: "Montserrat", Sans-serif
                    font-size: 24px
                    font-weight: 500
            &_item
                padding: 20px 0
                border-bottom: 1px solid #E4E4E4
                color: #000000
                font-family: "Montserrat", Sans-serif
                font-size: 18px
                font-weight: 400
                line-height: 1.7
                &:last-child
                    border-bottom:  none
                a
                    color: #000
@media ( max-width:  575px)
    .contacts
        &_content
            padding: 0 10px
@media ( max-width: 400px)
    .contacts
        &_content
        &_element
            .company
                &_item
                    font-size: 15px
                    line-height: 1.7