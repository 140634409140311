.modal_element{
    position: relative;
    z-index: 1000;
    height: 100vh;
    width: 100%;
    background-color: rgba(0,0,0, .8);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    pointer-events: none;
    transition: .5s;
}
.modal_element.active{
    opacity: 1;
    pointer-events: all;
    
}
.modal_close{
        position: absolute;
        top: 80px;
        right: 20px;
        font-size: 71px;
        line-height: 21px;
        color: #fff;
        cursor: pointer;
        opacity: .5;
    }
.modal_content{
    height: 500px;
    width: 500px;
    background-color: #fff;
    overflow: hidden;
}
.modal_content img{
    height: 100%;
    width: 100%;
    opacity: 1;
    object-fit: contain;
}
.slick-prev, .slick-next{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}
.slick-prev, .slick-next i{
    font-size: 40px;
    color: rgba(255,255,255,.7);
}
.slick-prev, .slick-next i:hover{
    color: rgba(255,255,255,1);
}
.slick-prev{
    left: 20px;
}
.slick-next{
    right: 20px;
}
/* .slick-slide{
    max-width: 25%;
    } */
.slick-track{
    display: flex;
    justify-content: center;
    }
@media (max-width: 700px){
    .modal_content{
        height: 400px;
        width: 400px;
    }
    
}
@media (max-width: 500px){
    .modal_content{
        height: 350px;
        width: 350px;
    }
}
@media (max-width: 400px){
    .modal_content{
        height: 300px;
        width: 300px;
    }
}