.footer
    background: url('../../img/bg/header.jpg') center (center / cover) no-repeat black
    padding: 20px 0
    color: #fff
    font-size: 19px
    font-weight: 600
    &_open
        height: 100%
        display: flex
        justify-content: center
        align-items: center
    
        
    &_mail
        display: flex
        justify-content: center
        align-items: center
        height: 100%
        font-size: 39px
        a   
            text-align: center
            text-decoration: none
            color: #fff
    &_middle
        &_phone
            text-align: center
            a   
                display: block
                text-align: center
                text-decoration: none
                color: #fff
        &_address
            text-align: center
@media (max-width: 992px)
    .footer
        line-height: 1.4
        font-size: 17px
        font-weight: 600
        &_mail
            font-size: 33px
@media (max-width: 822px)
    .footer
        line-height: 1.3
        font-size: 16px
        font-weight: 600
        &_mail
            font-size: 30px
@media (max-width: 822px)
    .footer
        padding: 15px 0
        line-height: 1.3
        font-size: 14px
        font-weight: 500
        &_mail
            font-size: 25px
@media (max-width: 650px)
    .footer
        padding: 10px 0
        line-height: 1.2
        font-size: 13px
        font-weight: 500
        &_mail
            font-size: 23px
@media (max-width: 576px)
    .footer
        padding: 10px 0
        line-height: 1.1
        font-size: 16px
        font-weight: 500
        &_mail
            font-size: 38px
            padding: 20px 0