$main-color: rgb(133, 197, 85)
$hover-color: rgb(126, 182, 83)
.slider
    &_content           
    .carousel
        &-indicators
            li
                border-radius: 100%
                width: 15px
                height: 15px
                margin: 0 5px
        &-item
            height: calc(100vh - 130px)
            img
                width: 100%
                height: 100%
        &-control
            &-next, &-prev
                opacity: 1
                width: 10%
                &-icon
                    width: 40px
                    height: 40px
        &-caption
            top: 50%
            transform: translateY(-50%)
            font-family: "Montserrat", Sans-serif
            h5
                font-size: 42px
                font-weight: 600
                margin-bottom: 14px
            p
                font-size: 32px
                font-weight: 400
                line-height: 1.4
            &-btn
                display: flex
                justify-content: center
                align-items: center
                border-radius: 3px
                font-size: 20px
                font-weight: 600
                background-color: $main-color
                color: #fff
                width: 220px
                height: 48px
                margin: 30px auto 0
                text-align: center
.bestoffer
    background-color: rgb(218, 245, 197)
    padding: 30px 0
    &_content
        max-width: 1000px
        margin: 0 auto
        display: grid
        grid-template-columns: repeat(2, 50%)
        grid-row-gap: 20px
        
        &__element
            display: flex
            max-width: 100%
            border-radius: 10px
            margin: 0 10px
        &__img
            width: 20%
            object-fit: contain
            margin: 10px
        &__title
            color: rgb(112, 173,71)
            margin: 10px 0
        &__descr
            font-weight: bold
            margin-bottom: 10px

.title
    display: flex
    justify-content: center
    align-items: center
    margin-bottom: 20px
    color: #000000
    h2
        text-align: center
        font-family: "Montserrat", Sans-serif
        font-size: 28px
        font-weight: 600
    &__bestoffer
        color: rgb(112, 173,71)
.descr
    &_element
            color: #000000
            font-family: "Montserrat", Sans-serif
            font-size: 16px
            font-weight: 400
            padding-bottom: 20px
    &_text
        display: inline-block
        width: 100%
        font-size: 18px
        .indent
            text-indent: 55px
        .bigsize
            font-size: 20px

        .mail
            color: purple
            font-style: italic
            text-decoration: underline
        .phone
            text-decoration: none
            color: inherit
        p
            text-align: justify
        &_last
            text-align: right
            color: #000000
            font-family: "Montserrat", Sans-serif
            font-size: 30px
            font-weight: 600
.preim
    margin-left: 55px
    width: calc( 100% - 55px )

.company
    background-color: rgb(218, 245, 197)
    &_content
        padding: 50px
    &_container
        &_btn
            display: flex
            justify-content: center
            align-items: center
            a
                display: flex
                justify-content: center
                align-items: center
                height: 40px
                width: 250px
                font-family: "Montserrat", Sans-serif
                font-size: 16px
                font-weight: 600
                text-transform: uppercase
                background-color: $main-color
                border-style: groove
                border-width: 0px 0px 2px 0px
                border-color: #285A3C
                border-radius: 3px
                color: #fff
                transition: all .3s
                &:hover
                    text-decoration: none
                    background-color: $hover-color
                    border-color: $hover-color
                    animation-name: trembling
                    animation-duration: .7s
                    

.manufacturers
    background-color: rgb(218, 245, 197)
    &_content
        min-height: 270px
        margin: 0 20px
    
    &_item
        // border: 1px solid rgba(129,129,129,1)
        border-radius: 6px
        box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.4)
        background-color: rgb(234, 245, 229)
        margin: 10px 0
        cursor: pointer
        transition: 0.3s transform
        animation: 1.5s linear rise
        &:hover 
            transform: translateY(-5px)
        
        &_link
            display: flex
            justify-content: center
            align-items: center
            padding: 20px
            max-width: 100%
            height: 100px
            

            
            img
                max-width: 100%
                max-height: 100px
        &_company
            margin-top: 10px
            text-align: center
            color: green
            font-size: 16px
            font-weight: 600
            opacity: 0.8
            height: 60px
            display: flex
            justify-content: center
            overflow: hidden
            line-height: 1.2
            &:hover
                opacity: 1
        &_production
            text-align: center
            padding: 5px
            color: #000
            font-size: 17px
            font-weight: 400
            opacity: 0.8
            &:hover
                opacity: 1
    
.wholesale
    background-color: rgb(218, 245, 197)
    &_content
        min-height: 410px
        padding: 50px 50px
    &_text
        display: inline-block
        width: 100%
        &_last
            text-align: end
            color: #000000
            font-family: "Montserrat", Sans-serif
            font-size: 25px
            font-weight: 600
            font-style: italic
@media ( max-width:  1200px)
    .manufacturers
        &_item
            &_company
                font-size: 16px
@media ( max-width:  991px)
    .slider        
        .carousel
            &-caption
                p
                    font-size: 28px
            &-item
                height: 450px
                img
                    height: 450px
    .company
        &_content
            padding: 40px 10px
    .title
        h2
            font-size: 30px
    .descr
        &_element
                font-size: 20px
                padding-bottom: 10px
        &_text
            &_last
                font-size: 25px
    .wholesale
        &_content
            padding: 40px 10px
        &_text
            &_last
                font-size: 22px
    .manufacturers
        &_content
            padding: 15px 0
    .bestoffer
        &_content
            &__title
                font-size: 14px
            &__descr
                font-size: 14px
@media ( max-width:  767px)
    .slider
        &_content           
        .carousel
            &-caption
                h5
                    font-size: 35px
                p
                    font-size: 21px
            &-item
                height: 350px
                img
                    height: 350px
    .company
        &_content
            padding: 20px 10px
    .title
        h2
            font-size: 30px
    .descr
        
        &_element
                font-size: 20px
                padding-bottom: 10px
        
    .wholesale
        &_content
            padding: 20px 10px
        &_text
            &_last
                font-size: 25px
    .manufacturers
        &_content
            padding: 15px 0
    .bestoffer
        &_content
            &__title
                font-size: 11px
            &__descr
                font-size: 11px
@media ( max-width:  575px)
    .slider
        &_content           
        .carousel
            &-caption
                h5
                    font-size: 25px
                p
                    font-size: 17px
                &-btn
                    font-size: 15px
                    font-weight: 600
                    width: 170px
                    height: 38px
            &-item
                height: 300px
                img
                    height: 300px
    .company
        &_content
            padding: 30px 0
    .title
        h2
            font-size: 20px
    .descr
        &_text
            font-size: 16px
            .bigsize
                font-size: 18px
        
        &_element
                
                padding-bottom: 0
        
    .wholesale
        &_content
            padding: 30px 0
        &_text
            &_last
                font-size: 17px
    .manufacturers
        &_content
            padding: 15px 0
    .bestoffer
        &_content
            grid-template-columns: repeat(1, 100%)
@media ( max-width:  400px)
    .descr
        &_text
            font-size: 13px
            .bigsize
                font-size: 15px
    .slider
        &_content           
        .carousel
            &-caption
                h5
                    font-size: 20px
                p
                    font-size: 13px
                &-btn
                    font-weight: 600
                    width: 170px
                    height: 38px
            &-indicators
                margin-bottom: 0
                li
                    border-radius: 100%
                    width: 15px
                    height: 15px
                    margin: 0 5px
            &-item
                height: 250px
                img
                    height: 250px