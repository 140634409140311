.products_container 
	display: grid
	grid-gap: 15px
	grid-template-columns: repeat(auto-fit, minmax(180px, 1fr))
	width: 100%
	max-width: 1200px
	margin: 0 auto
	margin-bottom: 40px
	padding: 0 15px
.products_element 
	max-width: 400px
	display: flex
	flex-direction: column
	justify-content: flex-end
	padding: 5px 5px
	border-radius: 3px
	background-color: #ffffff
	// border: 1px solid black
	box-shadow: 5px 0px 10px rgba(0, 0, 0, 0.4)
	transition: 0.3s transform
	animation: 1.5s linear rise
	&:hover 
		transform: translateY(-5px)

  
    
	&_name 
		display: flex
		justify-content: center
		// align-items: center
		margin-top: 5px
		font-weight: 500
		font-family: "Montserrat",sans-serif
		height: 72px
		text-align: center
		font-size: 14px
		overflow: hidden
		line-height: 1
		&:hover 
			color: rgba(51,51,51,.7)
		
	
	&_manufacturers 
		display: flex
		justify-content: center
		// align-items: center
		text-align: center
		font-weight: 500
		font-family: "Montserrat",sans-serif
		color: rgba(51,51,51,.7)
		height: 43px
		line-height: 1
		font-size: 14px
		overflow: hidden
	
	&_img 
		width: 100%
		height: 200px
		margin-top: 15px
		object-fit: contain
.navlink 
	color:#000

.navlink:hover 
	color: #000000
	cursor: pointer
@keyframes rise 
	from 
		opacity: 0
  
	to 
		opacity: 1
// @media ( max-width:  1100px)
// 	.products_container 
// 		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr))
// @media ( max-width:  470px)
// 	.products_container 
// 		grid-template-columns: repeat(auto-fit, minmax(150px, 1fr))
// 	.products_element
// 		&_name 
// 			font-size: 13px
// 		&_manufacturers 
// 			font-size: 13px
// @media ( max-width:  362px)
// 	.products_element
// 		&_name 
// 			font-size: 16px
