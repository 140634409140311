.scrollToTop
    display: flex
    justify-content: center
    align-items: center
    position: fixed
    right: 20px
    bottom: 20px
    z-index: 350
    width: 50px
    height: 50px
    border-radius: 50%
    background-color: rgba(255,255,255,.5)
    color: #333
    font-size: 20px
    &:hover
        color: #333
        text-decoration: none
        cursor: pointer
        i
            opacity: .5