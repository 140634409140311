$var1: #000000

.bg
	background-color: rgb(218, 245, 197)
.catalog
	padding-top: 50px
	padding-left: 30px
	min-height: calc(100vh - 40px)
	position: relative
.categories 
	display: block
	color: $var1
	text-transform: uppercase
	font-weight: 600
	font-size: 16px
	padding-bottom: 10px

.menu 
	position: sticky
	top: 70px
.menu767
	display: none
.burgerMenu	
	position: relative
	
ul 
	list-style-type: none

.menuli 
	display: block
	flex: 1 1 calc(100% - 40px)
	padding-top: 4px
	padding-bottom: 4px
	padding-right: 5px
	width: calc(100% - 40px)
	text-decoration: none
	font-size: 16px
	line-height: 1.2
.subcategory
	color:#000
	font-weight: 400
.subcategory:hover 
	color: green
	cursor: pointer
.subcategory_position
	position: relative
.openToClose
	font-size: 20px
	width: 15px
	position: absolute
	bottom: -20%
	right: -15px
	transform: rotate(-90deg)
.colapss_body
	max-height: 0
	overflow: hidden
	padding-left: 15px
	transition: max-height 0.5s cubic-bezier(0,1,0,1)
	display: none
.show
	display: block
	height: auto
	max-height: 999px
	transition: max-height 0.5s cubic-bezier(1,0,1,0)
.navlink
	color:#000
.navlink:hover 
	color: green
	cursor: pointer
.loadmore
	height: 150px
	display: flex
	justify-content: center
	align-items: center
	button
		width: 153px
		height: 32px
		background-color: rgb(73, 145, 19)
		border-radius: 5px
		border: none
		font-weight: 500
		font-size: 12px
		text-transform: uppercase
		color: #FFFFFF
		&:hover
			background-color: rgb(126, 182, 83)
		&:focus
			outline: none
@media ( max-width:  992px)
	.catalog
		padding-top: 20px
	.menuli 
		width: 100%
@media ( max-width: 767px )
	.catalog
		padding-top: 0
		padding-left: 0
	.menu
		display: none
	.menu767
		display: block
		padding: 5px 0 5px 0
		margin-bottom: 10px
		
		background-color: rgba(0, 0, 0, .5)
		position: sticky
		top: 29px
		z-index: 350
		color: #fff
		cursor: pointer
		width: 100wh
		height: auto
		.circ_close
			font-size: 10px
			line-height: 1
			width: 15px
			position: absolute
			bottom: 2px
			left: 50%
			transform: translateX(-50%)
			transform: rotate(-90deg)
		.circ_open
			font-size: 10px
			line-height: 1
			width: 15px
			position: absolute
			bottom: 2px
			left: 50%
			transform: translateX(-50%)
			transform: rotate(90deg)
	.burgerMenu
		font-weight: 700
		text-align: center	
		position: relative
		text-align: center
	.menu_category
		position: absolute
		padding-top: 30px
		display: flex
		flex-direction: column
		width: 100%
		// min-height: 100vh
		height: calc(100% - 34px )
		top: 34px
		z-index: 300
		background-color: rgba(0, 0, 0, .5)
		.navlink
			margin: 2px 0
			color: #fff
		.navlink:hover
			color: black
		.subcategory
			margin: 2px 0
			color: #fff
			font-weight: 700
			
		.subcategory:hover 
			color: black
			cursor: pointer
@media ( max-width: 576px )
	.menu767
		top: 28px
@media ( max-width: 417px )
	.menu767
		top: 18px
	
		