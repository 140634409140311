.bestseller
    width: 100%
    padding: 10px 0
    &_new
        background: url('../../img/bg/new.jpg') center (center / cover) no-repeat
    &_hit
        background: url('../../img/bg/hit.jpg') center (center / cover) no-repeat
    .container
        max-width: 1200px
        
    &_title
        display: flex
        justify-content: center
        align-items: center
        padding-top: 10px
        color: #fff
        h2
            font-size: 36px
            font-weight: bolder
    .carousel
        &__inner
            height: 100%
            width: 100%
            padding: 0 40px
            
            
            .item
                width: auto
                height: auto
                
                // margin: 10px
                background-color: #fff
                &:focus
                    outline: 3px solid black
                a
                    text-decoration: none
                    color: #333
                &_img
                    padding: 5px
                    display: block
                    width: 100%
                    
                    
                    img
                        width: 100%
                        height: 250px
                        object-fit: contain
                &_name
                    display: block
                    height: 63px
                    overflow: hidden
                    text-align: center
                    margin: 0 2px 10px 2px
                    font-size: 16px
                    line-height: 1
                    color: #fff
                &_manufacturer
                    display: block
                    text-align: center
                    padding-bottom: 5px
                    font-size: 12px
                    overflow: hidden
                    height: 41px
            .slick-prev, .slick-next
                position: absolute
                top: 50%
                transform: translateY(-50%)
                i
                    font-size: 40px
                    color: rgba(255,255,255,.7)
                    &:hover
                        color: rgba(255,255,255,1)
            .slick-prev
                left: 0px
            .slick-next
                right: 0px
            .dots
                display: flex
                align-items: center
                justify-content: center
                margin: 5px
                flex-wrap: wrap
                li
                    list-style: none
                    margin: 5px 5px
                    width: 12px
                    height: 12px
                    background-color: transparent
                    border: 1px solid #fff
                    border-radius: 50%
                    &:hover
                        cursor: pointer
                    button
                        opacity: 0
                        width: 100%
                        height: 100%
                li.slick-active
                    background-color: #fff
    .slick-slide
        width: auto
        height: auto
        margin: 10px
        background-color: #fff
        max-width: 25%
    .slick-list
        overflow: hidden
    .slick-track
        overflow: hidden
        display: flex
        justify-content: center
    .slick-slider
        position: relative
@media ( max-width:  1050px)
    .bestseller
        .carousel
            &__inner
                .item
                    &_name
                        font-size: 16px
                .slick-prev, .slick-next
                    i
                        font-size: 40px

@media ( max-width:  921px)
    .bestseller
        .slick-slide
            max-width: 33%
@media ( max-width:  701px)
    .bestseller
        .slick-slide
            max-width: 50%
@media ( max-width:  575px)
    .bestseller
        padding: 10px 0 20px 0
@media ( max-width:  550px)
    .bestseller
        .slick-slide
            max-width: 100%
